import React, { useState, useEffect } from "react";
import Notiflix from "notiflix";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import PostApiCall from "../../POSTAPI";
import {
  Col,
  Container,
  Row,
  Button,
  Modal,
  Accordion,
  Offcanvas,
} from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import PageBanner from "../../components/PageBanner/PageBanner";
import "./Careers.css";
import WorkWithUsPageBanner from "../../assets/img/services-banner/work-with-us-page-banner.png";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { IoLocationOutline, IoSync } from "react-icons/io5";
import CommonContactSection from "../Services/CommonContactSection/CommonContactSection";
// import outboarding from "../../assets/img/job-requirement/Onboarding-Executive.png";
// import smm from "../../assets/img/job-requirement/Social-media-marketing.png";
// import smms from "../../assets/img/job-requirement/Social-Media-Marketing-Specialist.png";
// import seo from "../../assets/img/job-requirement/Seo-Intern.png";
// import ppc from "../../assets/img/job-requirement/PPC.png"
function Careers() {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ title: '', contentKey: '' });
  const handleOpenModal = (title, contentKey) => { setModalData({ title, contentKey }); setShowModal(true); };
  const handleCloseModal = () => { setShowModal(false); setModalData({ title: '', contentKey: '' }); };
  // Job form integration
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);

  // fileupload
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileBase64String, setfileBase64String] = useState("");
  const onFileChange = (e) => {
    if (e.target.files[0].type == "application/pdf") {
      setSelectedFile(e.target.files);
    } else {
      Notiflix.Notify.failure("file should be in pdf format");
    }
  };

  const encodeFileBase64 = (file) => {
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        var attachment = reader.result;
        setfileBase64String(attachment);
        // console.log(setfileBase64String);
      };
      reader.onerror = (error) => {
        console.log("error: ", error);
      };
    }
  };
  encodeFileBase64(selectedFile[0]);

  // console.log(selectedFile[0]);
  const [Enquiry, setEnquiry] = useState({
    postapplied: "",
    job: "",
    name: "",
    mobile: "",
    email: "",
    age: "",
    message: "",
    location: "",
    attachment: "",
    country: "",
    qualification: "",
    careernutshell: "",
    strongsuits: "",
    profachievements: "",
    jobleavingreason: "",
    otherinfo: "",
    File: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });

  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotatevig");
    }
  }, [Rotatecaptcha]);
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 90000000)
        .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000)
      .toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }

  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);

  const handleClose = () => setShow(false);

  const handleShowJobTitle = (jobName = "") => {

    setEnquiry({
      ...Enquiry,
      job: jobName,
    });
    // setEnquiry({
    //   ...Enquiry,
    //   job: jobDetails.title,
    // });
    setShow(true);
  };
  // console.log(Enquiry)

  return (
    <>
      <Navbar />
      <PageBanner bgImage={WorkWithUsPageBanner} />
      <section className="py-lg-5 py-4">
        <Container fluid className="position-relative px-lg-5">
          <Row>
            <Col lg={12} className="text-center mb-2 mb-lg-2">
              <p className="main-text text-center fst-italic wow animate__animated animate__fadeInUp">
                Work at the most dynamic company & unlock your true potential.



              </p>
              <p className="main-text text-center wow animate__animated animate__fadeInUp">
                Check out our interest forms below and register your details for the one that best fits your skills, experience, and interests.


              </p>
            </Col>
            {/* SALES AND MARKETING */}
            <Col lg={12}>
              <h2 className="main-title main-title-3 wow animate__animated animate__fadeInUp text-center">
                Sales and Marketing
              </h2>
              <p className="main-text text-center wow animate__animated animate__fadeInUp mb-4">
                Explore exciting opportunities in Sales & Marketing and grow with us!







              </p>
            </Col>


            <div className="col-lg-6 mb-4">
              <div className="card-one  d-flex flex-column justify-content-between">

                <div>
                  <h4>Onboarding Executive</h4>
                  <p>
                    Become an Onboarding Specialist - Bring New Restaurants to Our Platform!
                  </p>
                  <p>Love meeting new people and helping businesses grow? Join us as an Onboarding Specialist and be the hero who brings amazing restaurants onto our platform!
                  </p>

                </div>
                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Onboarding Executive")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Onboarding Executive', 'oe')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-4">
              <div className="card-one">

                <h4>Business Development Executive</h4>
                <p>
                  Join us and make an impact!
                </p>
                <p>
                  Are you a results-driven Business Development Executive with a passion for growth and strategy? We're looking for someone who can identify opportunities, build strong relationships, and drive success!                </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Business Development Executive")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Business Development Executive', 'bde')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-5">
              <div className="card-one">

                <h4>Telesales Executive</h4>
                <p>
                  Join us and make every call count!
                </p>
                <p>
                  Are you a persuasive communicator who thrives on closing deals over the phone? We’re looking for a Telesales Executive to turn leads into customers and drive sales success!      </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Telesales Executive")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Telesales Executive', 'te')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-5">
              <div className="card-one">

                <h3>Field Sales Representative</h3>
                <p>
                  Join us and take success to the next level!                 </p>
                <p>
                  Are you a go-getter who loves meeting new people and closing deals? We're looking for a Field Sales Representative to drive sales, build relationships, and make an impact!                </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Field Sales Representative")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Field Sales Representative', 'fsr')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>

          </Row>
          <Row>
            {/* Digital Marketing */}
            <Col lg={12}>
              <h2 className="main-title main-title-3 wow animate__animated animate__fadeInUp text-center">
                Digital Marketing
              </h2>
              <p className="main-text text-center wow animate__animated animate__fadeInUp mb-4">
                Join our team and showcase your skills in Digital Marketing while growing your career with us!







              </p>
            </Col>
            <div className="col-lg-6 mb-4">
              <div className="card-one d-flex flex-column justify-content-between">
                <div>
                  <h4>Social Media Marketing Specialist</h4>
                  <p>Own the Spotlight in Social Media Marketing!</p>
                  <p>Think you've got what it takes to make a brand trend? Join our team and let your creativity, strategy, and bold ideas take center stage. Let's redefine the digital game together!</p>

                </div>
                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Social Media Marketing Specialist")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Social Media Marketing Specialist', 'smms')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>

            <div className="col-lg-6 mb-4">
              <div className="card-one">

                <h3>PPC Specialist</h3>
                <p>
                  Shine Bright as a PPC Specialist!
                </p>
                <p>Are you all about turning clicks into conversions and optimizing campaigns for maximum impact? Do you get excited about driving growth through paid ads and staying ahead of the competition? If you're ready to bring your expertise and make a real difference, we need YOU to help take our PPC game to the next level!

                </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("PPC Specialist")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('PPC Specialist', 'ppc')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>


            <div className="col-lg-6 mb-4">
              <div className="card-one d-flex flex-column justify-content-between">
                <div>
                  <h3>Content Writer</h3>
                  <p>
                    Join us and create content that connects and inspires!
                  </p>
                  <p>
                    Love crafting compelling content that grabs attention? We're looking for a skilled Content Writer to bring ideas to life with words!                </p>
                </div>
                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Content Writer")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Content Writer', 'cw')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-4">
              <div className="card-one">

                <h3>Graphic Designer</h3>
                <p>
                  Join us and create designs that stand out!                </p>
                <p>
                  Are you a creative Graphic Designer who loves turning ideas into eye-catching visuals? If yes, we want YOU to bring your design skills to our team!    </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Graphic Designer")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Graphic Designer', 'gd')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-4">
              <div className="card-one">

                <h3> SEO Specialist</h3>
                <p>
                  Join us and make an impact online!  </p>
                <p>
                  Are you an SEO expert who knows how to boost rankings and drive organic traffic? We’re looking for an SEO Specialist to optimize, analyze, and take our digital presence to the next level! </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("SEO Specialist")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal(' SEO Specialist', 'ss')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-5">
              <div className="card-one">

                <h3>Video Editor</h3>
                <p>
                  Join us and make every second count! </p>
                <p>
                  Are you obsessed with creating videos that grab attention? We're looking for a Video Editor who can cut, trim, and transform raw footage into scroll-stopping content!
                </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Video Editor")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Video Editor', 've')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>

            <div className="col-lg-6 mb-5">
              <div className="card-one">

                <h3>Photographer</h3>
                <p>
                  Join us and frame the world through your lens!  </p>
                <p>
                  Do you have a knack for capturing the perfect shot? We're looking for a photographer to snap stunning visuals, tell stories through images, and bring creativity to life!                </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Photographer")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Photographer', 'p')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
          </Row>
          <Row>
            {/* Software develepopment */}
            <Col lg={12}>
              <h2 className="main-title main-title-3 wow animate__animated animate__fadeInUp text-center">
                Design and Developement
              </h2>
              <p className="main-text text-center wow animate__animated animate__fadeInUp mb-4">
                Be part of our team and unleash your creativity in design and development while building innovative solutions!







              </p>
            </Col>
            <div className="col-lg-6 mb-4">
              <div className="card-one">

                <h3>React Developer</h3>
                <p>
                  Join us and contribute to building innovative solutions with cutting-edge technology.                </p>
                <p>
                  Are you a skilled React Developer with a passion for building dynamic and high-performing web applications? Do you excel at writing clean, efficient code and turning ideas into seamless user experiences? If yes, we are looking for YOU to be a part of our team!
                </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("React Developer")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('React Developer', 'rd')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-4">
              <div className="card-one">

                <h3>Web Designer</h3>
                <p>
                  Join us and bring ideas to life with innovative designs that make an impact!            </p>
                <p>
                  Are you a creative Web Designer with an eye for detail and a passion for crafting visually stunning and user-friendly websites? Do you excel at blending design and functionality to create seamless digital experiences? If yes, we want YOU on our team!
                </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Web Designer")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Web Designer', 'webd')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>


            <div className="col-lg-6 mb-4">
              <div className="card-one">

                <h3>Shopify Developer</h3>
                <p>
                  Join us and build something amazing!
                </p>
                <p>
                  Are you a Shopify Developer with a passion for creating and optimizing powerful e-commerce solutions? We're looking for someone who can customize, enhance, and elevate Shopify experiences!                </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Shopify Developer")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Shopify Developer', 'sd')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-5">
              <div className="card-one">
                <h3>SQL Developer</h3>
                <p>Join us and turn data into insights!
                </p>
                <p>

                  Are you passionate about databases and writing efficient queries? We're looking for an SQL Developer to work with data, optimize queries, and build powerful database solutions that drive business growth. If you have a strong foundation in SQL and a keen eye for optimizing performance, come help us transform complex data into actionable insights!





                </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("SQL Developer")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('SQL Developer', 'sqld')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-5">
              <div className="card-one">
                <h3>Flutter Developer</h3>
                <p>Join us and turn your code into amazing digital experiences! </p>
                <p>

                  Are you passionate about building beautiful and seamless mobile experiences? We’re looking for a skilled Flutter Developer to create stunning apps, enhance user interactions, and bring innovative ideas to life!
                </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Flutter Developer")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Flutter Developer', 'fd')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-5">
              <div className="card-one">
                <h3>Wordpress Developer</h3>
                <p>Join us and create seamless digital experiences with your expertise! </p>
                <p>

                  Are you passionate about building dynamic and user-friendly websites? We’re looking for a WordPress Developer to design, develop, and optimize high-performing websites that bring ideas to life!
                </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Wordpress Developer")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Wordpress Developer', 'wd')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
          </Row>
          <Row>
            {/* seo intern */}
            <Col lg={12}>
              <h2 className="main-title main-title-3 wow animate__animated animate__fadeInUp text-center">
                Intern
              </h2>
              <p className="main-text text-center wow animate__animated animate__fadeInUp mb-4">
                Calling all interns! Gain hands-on experience and kickstart your career with us!







              </p>

            </Col>
            <div className="col-lg-6 mb-4">
              <div className="card-one">

                <h4>Social Media Marketing</h4>
                <p>
                  Shine Bright as a Social Media Marketing Intern!
                </p>
                <p>Are you always ahead of the trends and love creating scroll-stopping content? Do you dream of building buzz and making brands go viral? If yes, we’re looking for YOU to help us level up our social media game!</p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Social Media Marketing Intern")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Social Media Marketing Intern', 'smmi')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-4">
              <div className="card-one">
                <h3>SEO </h3>
                <p>Step Into the Spotlight as an SEO Intern!</p>
                <p>Are you someone who dreams of making websites rank #1? Do you enjoy diving into keywords and optimizing content that gets noticed? If yes, we're looking for YOU to help us dominate the digital space!</p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("SEO Intern")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('SEO Intern', 'seo')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-4">
              <div className="card-one  d-flex flex-column justify-content-between">
                <div>
                  <h3>Graphic Designer</h3>
                  <p>Join us and unleash your creativity! </p>
                  <p>

                    Are you a creative individual with a passion for design? We're looking for a Graphic Designer Intern to craft stunning visuals, bring fresh ideas, and make an impact!</p>

                </div>
                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Graphic Designer Intern")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Graphic Designer Intern', 'gdi')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-4">
              <div className="card-one">
                <h3>Video Editor</h3>
                <p>Join us and let your creativity shine! </p>
                <p>

                  Are you passionate about video editing and creating eye-catching content? We’re looking for a Video Editor Intern to bring ideas to life, edit engaging videos, and make an impact!
                </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Video Editor Intern")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Video Editor Intern', 'vdi')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-4">
              <div className="card-one">
                <h3>React Developer</h3>
                <p>Join us and code your way to innovation!  </p>
                <p>

                  Are you passionate about building dynamic web applications? We're looking for a React Developer Intern to develop interactive user interfaces, optimize performance, and bring ideas to life!                </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("React Developer Intern")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('React Developer Intern', 'rdi')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>
            <div className="col-lg-6 mb-4">
              <div className="card-one">
                <h3>Photographer</h3>
                <p>Join us and showcase the world through your lens! </p>
                <p>

                  Are you passionate about capturing moments and telling stories through images? We're looking for a Photography Intern to shoot stunning visuals, enhance brand storytelling, and bring creativity to life!      </p>

                <div className="d-flex gap-4">
                  <div class="read-more-button" onClick={() => handleShowJobTitle("Photographer Intern")}><span class="btn btn-primary talk mt-5px">Apply Now</span></div>
                  <span onClick={() => handleOpenModal('Photographer Intern', 'pi')} class="btn btn-primary talk mt-5px info">More Info. </span>
                </div>
              </div>

            </div>

          </Row>


        </Container>
      </section >
      <CommonContactSection />
      <Footer />
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Apply for Job</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col lg={12}>
              <FloatingLabel
                controlId="floatingInput"
                label="Job Title *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Job Title"
                  name="job"
                  value={Enquiry.job}
                  // onChange={(e) =>
                  //   setEnquiry({ ...Enquiry, [e.target.name]: e.target.value })
                  // }
                  disabled
                />
              </FloatingLabel>
            </Col>
            <Col lg={12}>
              <FloatingLabel
                controlId="floatingInput"
                label="Name *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={Enquiry.name}
                  onChange={(e) =>
                    setEnquiry({
                      ...Enquiry,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </FloatingLabel>
            </Col>
            <Col lg={12}>
              <FloatingLabel
                controlId="floatingPassword"
                label="Mobile Number *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Mobile Number *"
                  name="mobile"
                  value={Enquiry.mobile}
                  onChange={(e) => {
                    if (e.target.value.length <= 10)
                      setEnquiry({
                        ...Enquiry,
                        [e.target.name]: e.target.value.replace(/\D/g, ""),
                      });
                  }}
                />
              </FloatingLabel>
            </Col>
            <Col lg={12}>
              <FloatingLabel
                controlId="floatingPassword"
                label="Email *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Email *"
                  name="email"
                  value={Enquiry.email}
                  onChange={(e) =>
                    setEnquiry({
                      ...Enquiry,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </FloatingLabel>
            </Col>
            <Col lg={12} className="mb-3">
              <Form.Label className="small mb-1">
                Attach your CV (Accepted file format .pdf,.doc,.docx)
              </Form.Label>
              <Form.Control type="file" size="sm" onChange={onFileChange} />
            </Col>
            <Col lg={12}>
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Message"
                className="mb-3"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Leave a message here"
                  style={{ height: "100px" }}
                  name="message"
                  value={Enquiry.message}
                  onChange={(e) =>
                    setEnquiry({
                      ...Enquiry,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </FloatingLabel>
            </Col>
            <div className="col-12">
              <div className="d-sm-flex d-block">
                <p className="me-2 justify-content-between w-sm-50 w-100 d-sm-flex d-block captcha-bg">
                  {captcha_number}
                  <span
                    onClick={() => {
                      setRotatecaptcha("iorotate");
                      Genratecaptcha();
                    }}
                    className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
                  >
                    <IoSync className="aButton fs-4 rotate" />
                  </span>
                </p>

                <FloatingLabel
                  controlId="floatingPassword"
                  label="Captcha Code *"
                  className="mb-3 w-100"
                >
                  <Form.Control
                    type="text"
                    className="form-control w-100"
                    name="captcha"
                    placeholder="Captcha Code *"
                    value={Enquiry.captcha}
                    onChange={(e) =>
                      setEnquiry({
                        ...Enquiry,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </div>
            </div>
            <Col className="d-lg-flex justify-content-end align-items-center">
              <Button
                className="btn btn-primary talk w-100"
                onClick={() => {
                  if (Enquiry.name != "") {
                    if (Enquiry.mobile != "") {
                      if (Enquiry.mobile.length == 10) {
                        if (Enquiry.email != "") {
                          if (Enquiry.EmailRegex.test(Enquiry.email)) {
                            if (selectedFile.length != 0) {
                              if (
                                Enquiry.captcha.toString() ==
                                captcha_number.toString()
                              ) {
                                Notiflix.Loading.arrows("Please wait...");
                                PostApiCall.postRequest(
                                  {
                                    job: Enquiry.job,
                                    name: Enquiry.name,
                                    mobile: Enquiry.mobile,
                                    email: Enquiry.email,
                                    message: Enquiry.message,
                                    age: null,
                                    location: "",
                                    country: "",
                                    postapplied: Enquiry.job,
                                    qualification: "",
                                    careernutshell: "",
                                    strongsuits: "",
                                    profachievements: "",
                                    jobleavingreason: "",
                                    otherinfo: "",
                                    attachment: fileBase64String,
                                    clientid: 1053,
                                    //clientid: 1020,
                                  },
                                  "jobapplicationsgt"
                                ).then((results2) =>
                                  results2.json().then((obj2) => {
                                    if (
                                      results2.status == 200 ||
                                      results2.status == 201
                                    ) {
                                      Notiflix.Notify.success(
                                        "Thank you, our team will contact you shortly!"
                                      );
                                      setTimeout(() => {
                                        window.location.reload();
                                      }, 2000);
                                    } else Notiflix.Loading.remove();
                                  })
                                );
                              } else {
                                Notiflix.Notify.failure(
                                  "Please enter valid captcha"
                                );
                              }
                            } else {
                              Notiflix.Notify.failure(
                                "Please upload resume in pdf format"
                              );
                            }
                          } else {
                            Notiflix.Notify.failure("Please enter valid email");
                          }
                        } else {
                          Notiflix.Notify.failure("Please enter email");
                        }
                      } else {
                        Notiflix.Notify.failure(
                          "Please enter valid mobile number"
                        );
                      }
                    } else {
                      Notiflix.Notify.failure("Please enter mobile number");
                    }
                  } else {
                    Notiflix.Notify.failure("Please enter name");
                  }
                }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>






      {/* Modal */} <Modal show={showModal} onHide={handleCloseModal} size="xl" centered>
        <Modal.Header closeButton><Modal.Title>{modalData.title}</Modal.Title></Modal.Header>
        <Modal.Body> {/* Conditionally render divs based on modalData.contentKey */}
          {modalData.contentKey === 'smms' && (<div>


            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Content Creation: Develop engaging, original content tailored to various platforms (Instagram, Facebook, Twitter, LinkedIn,). This includes text, graphics, video, and other media that resonate with our audience.
              </li>
              <li>Social Media Strategy: Collaborate with the marketing team to define and execute the social media strategy, ensuring alignment with the company's goals.</li>
              <li>Content Calendars: Create and manage content calendars, ensuring consistent posting and timely delivery of content across all channels.</li>
              <li>Engagement: Monitor and engage with followers on all social media platforms, responding to comments, direct messages, and mentions in a professional and friendly manner.</li>
              <li>Analytics and Reporting: Track, analyze, and report on social media performance using analytics tools to optimize strategies and achieve KPIs.</li>
              <li>Brand Voice and Communication: Ensure the company's voice remains consistent across all social media platforms. Work closely with other departments to ensure the messaging aligns with our overall marketing objectives.</li>
              <li>Campaign Management: Assist in the development and execution of paid social media campaigns to drive traffic, increase engagement, and generate leads.
              </li>
            </ul>
            <h3>Skills & Qualifications:</h3>
            <ul>
              <li>Proven experience in social media marketing, content creation, and community management.</li>
              <li>Strong knowledge of social media platforms (Facebook, Instagram, Twitter, LinkedIn, etc.). </li>
              <li>Excellent written and verbal communication skills.</li>
              <li>Ability to create compelling, high-quality content that engages audiences.
              </li>
              <li>Experience in developing and maintaining social media calendars.</li>
              <li>Strong organizational skills with the ability to manage multiple projects simultaneously.
              </li>
              <li>Knowledge of social media analytics and the ability to translate data into actionable insights.</li>
              <li>Creative thinker with attention to detail and a passion for staying updated on social media trends.</li>
              <li>A team player with a collaborative attitude.</li>
            </ul>
          </div>)}

          {modalData.contentKey === 'seo' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Conduct keyword research to identify trends and opportunities.
              </li>
              <li>Assist in optimizing website content, meta tags, and on-page elements.</li>
              <li>Analyze website performance using SEO tools like Google Analytics, Search Console, and SEMrush.</li>
              <li>Help build backlinks and manage off-page SEO activities.</li>
              <li>Monitor and report on website traffic and rankings.</li>
              <li>Stay updated on SEO trends, algorithm changes, and best practices.</li>
            </ul>
            <h3>Qualifications:</h3>
            <ul>
              <li>Currently pursuing or recently completed a degree in Marketing, IT, or a related field.</li>
              <li>Basic understanding of SEO principles and tools.</li>
              <li>Familiarity with content management systems (e.g., WordPress) is a plus.</li>
              <li>Strong analytical and problem-solving skills.
              </li>
              <li>Good communication and eagerness to learn.</li>

            </ul></div>)}
          {modalData.contentKey === 'oe' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Client Onboarding: Reach out to restaurants via phone and email to introduce and explain our services. Guide clients through the onboarding process, ensuring they understand the platform and its features.              </li>
              <li>Service Explanation: Communicate the benefits of our services effectively to potential clients, answering their questions and addressing any concerns.</li>
              <li>Online Assistance: Assist clients in setting up their accounts on our platform remotely, ensuring they are fully equipped to start using the services.</li>
              <li>Travel to Client Sites: When necessary, visit restaurant locations to provide hands-on assistance, setup, or offer a personalized experience for clients.</li>
              <li>Lead Generation: Identify potential leads, follow up with interested prospects, and maintain a pipeline of opportunities for onboarding.</li>
              <li>Customer Support: Provide ongoing support to onboarded clients, ensuring they are satisfied with the service and solving any issues they may encounter.</li>
              <li>Documentation & Reporting: Maintain accurate records of client interactions, onboarding progress, and service issues. Report on client feedback and share insights with the team for continuous improvement.</li>
              <li>Collecting Media Content: Coordinate with restaurant clients to collect high-quality images and videos of their restaurants, dishes, or services for uploading to our platform or marketing materials.</li>
            </ul>
            <h3>Required Skills & Qualifications:</h3>
            <ul>
              <li>Excellent Communication Skills: Must be comfortable speaking with clients over the phone and in person, with the ability to explain services clearly and persuasively.</li>
              <li>Customer Service Orientation: A passion for helping others, with the ability to handle customer inquiries and resolve issues efficiently.</li>
              <li>Organizational Skills: Ability to manage multiple client onboarding processes and follow-up tasks simultaneously.</li>
              <li>Tech-Savvy: Comfortable working with online tools, CRM systems, and basic software.
              </li>
              <li>Willingness to Travel: Must be open to traveling to client locations within the region for in-person support when necessary.</li>
              <li>Problem-Solving: Ability to think on your feet and troubleshoot issues as they arise.</li>
              <li>Self-Motivated: Ability to work independently and take initiative in contacting potential clients.</li>

            </ul></div>)}
          {modalData.contentKey === 'ppc' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Develop, implement, and optimize PPC campaigns across various platforms (Google Ads, Bing, Facebook, LinkedIn, etc.).
              </li>
              <li>Conduct keyword research and develop targeted ad copy to maximize campaign performance.
              </li>
              <li>Manage and analyze campaign performance, optimizing for conversions, ROI, and overall efficiency.
              </li>
              <li>Regularly monitor and adjust bids, budgets, and targeting strategies to meet campaign goals.
              </li>
              <li>Collaborate with the creative team to develop ad creatives and landing pages that drive conversions.
              </li>
              <li>Perform A/B testing and data-driven analysis to continually improve campaigns.
              </li>
              <li>Provide regular performance reports, insights, and recommendations to stakeholders.
              </li>
              <li>Stay up to date with the latest trends, tools, and best practices in PPC marketing.
              </li>
            </ul>
            <h3>Qualifications:</h3>
            <ul>
              <li>Proven experience managing PPC campaigns, with a strong understanding of Google Ads, Facebook Ads, and other advertising platforms.
              </li>
              <li>Strong analytical skills and experience with tools like Google Analytics, Google Ads, and other campaign management platforms.
              </li>
              <li>A solid understanding of conversion tracking, bid management, and performance metrics.
              </li>
              <li>Ability to think strategically and optimize campaigns for both short-term and long-term goals.

              </li>
              <li>Excellent communication and teamwork skills.
              </li>
              <li>Highly organized and detail-oriented.





              </li>

            </ul></div>)}
          {modalData.contentKey === 'bde' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Identify and reach out to potential clients to introduce and promote our services.</li>
              <li>
                Conduct cold calling to generate new leads and initiate conversations with prospective clients.
              </li>

              <li>
                Contact leads through phone, email, and other channels, effectively communicating the
                value of our services.
              </li>
              <li>
                Assist new clients in the onboarding process, ensuring a smooth and successful start
                with the platform.
              </li>
              <li>
                Support the sales team in managing the full sales cycle, from prospecting and lead
                generation to assist in closing deals.
              </li>
              <li>
                Build and maintain positive relationships with clients to ensure satisfaction and help
                foster long-term partnerships.
              </li>
              <li>
                Provide client support through calls, emails, or occasional site visits to ensure their
                needs are met.
              </li>
              <li>Track client interactions, manage leads, and maintain accurate records in CRM systems.</li>
              <li>Work with internal teams to provide feedback and suggestions for refining services and contributing to business growth.</li>
              <li>Stay updated on industry trends and competitor activities to help identify potential
                opportunities.

              </li>
            </ul>
            <h3>Required Skills & Qualifications:</h3>
            <ul>
              <li>Strong communication skills, with the ability to clearly present ideas and persuade
                potential clients.
              </li>
              <li>
                Friendly, customer-focused attitude with the ability to build relationships and address
                client needs.
              </li>
              <li>
                Good organizational skills, with the ability to handle multiple leads and client interactions
                at the same time.
              </li>
              <li>
                Basic understanding of CRM systems and sales tools is a plus, but not mandatory.
              </li>
              <li>
                Willingness to travel to client locations for meetings, product demos, or support when
                necessary.
              </li>
              <li>
                Self-motivated, eager to learn, and enthusiastic about meeting targets and deadlines.</li>
              <li>
                Problem-solving mindset, able to think critically and adapt to client needs.</li>

            </ul></div>)}
          {modalData.contentKey === 'te' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Make outbound calls to potential customers and promote products/services.</li>
              <li>
                Follow up with leads and close sales, ensuring customer satisfaction.              </li>

              <li>
                Provide accurate product/service information and address customer queries.
              </li>
              <li>
                Meet or exceed sales targets and KPIs.
              </li>
              <li>
                Maintain records of customer interactions in CRM software.
              </li>
              <li>
                Upsell and cross-sell additional products/services.
              </li>
              <li>
                Collaborate with the sales team to develop effective sales strategies.
              </li>

            </ul>
            <h3>Required Skills & Qualifications:</h3>
            <ul>
              <li>Proven experience in telesales, telemarketing, or customer service.
              </li>
              <li>
                Excellent communication skills and ability to engage customers.
              </li>
              <li>
                Strong sales skills with the ability to close deals effectively.
              </li>
              <li>
                Familiarity with CRM software or sales tools.              </li>
              <li>
                Goal-oriented, self-motivated, and able to work independently.
              </li>
              <li>
                Positive attitude and strong problem-solving skills.</li>

            </ul></div>)}
          {modalData.contentKey === 'fsr' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Actively prospect new clients and generate sales leads through field visits.</li>
              <li>
                Build and maintain strong relationships with customers, understanding their needs.   </li>

              <li>
                Conduct face-to-face meetings with potential and existing clients.     </li>
              <li>
                Promote and present products/services to clients in a professional manner.     </li>
              <li>
                Negotiate contracts and close sales, meeting or exceeding sales targets.    </li>
              <li>
                Provide after-sales support and address customer concerns.   </li>
              <li>
                Stay updated on industry trends, competitors, and market conditions.
              </li>

            </ul>
            <h3>Required Skills & Qualifications:</h3>
            <ul>
              <li>Proven experience in field sales or outside sales.
              </li>
              <li>
                Excellent communication and negotiation skills. </li>
              <li>
                Strong relationship-building abilities and customer service skills.              </li>
              <li>
                Self-motivated, goal-oriented, and able to work independently.    </li>
              <li>
                Ability to travel frequently within assigned territory.     </li>
              <li>
                High school diploma or equivalent; additional sales training is a plus.</li>

            </ul></div>)}
          {modalData.contentKey === 'cw' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Content Creation: Generate high-quality and original content that aligns with the company's branding and messaging. This may include articles, blog posts, social media posts, and other written materials.</li>
              <li>
                Research: Conduct research on industry trends, market developments, and relevant topics to ensure the accuracy and relevancy of the content.   </li>

              <li>
                SEO Optimization: Learn and implement basic search engine optimization (SEO) techniques to enhance the visibility of content in search engine results. </li>
              <li>
                Proofreading and Editing: Review and edit your own work to ensure it is error-free and meets the company's standards for grammar, style, and formatting.  </li>
              <li>
                Content Calendar: Collaborate with the content team to follow a content calendar and meet deadlines for content publication.     </li>
              <li>
                Social Media Engagement: Participate in social media management and engage with the audience by responding to comments, messages, and sharing content.     </li>
              <li>
                Content Promotion: Assist in promoting content through various online channels, such as email newsletters and social media platforms.              </li>
              <li>
                Learning and Growth: Keep up to date with industry trends, content marketing strategies, and digital marketing techniques to continually improve your skills.</li>
            </ul>
            <h3>Required Skills & Qualifications:</h3>
            <ul>
              <li>Currently pursuing a degree in English, journalism, marketing, or a related field (or recent graduate).
              </li>
              <li>
                Strong written communication skills and an ability to adapt your writing style to different audiences.           </li>
              <li>
                A passion for staying informed about current trends and developments in various industries.              </li>
              <li>
                Basic knowledge of SEO and content optimization principles is a plus.          </li>
              <li>
                Creativity and a willingness to learn and adapt to new challenges.             </li>

            </ul></div>)}
          {modalData.contentKey === 'gd' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Planning concepts by studying relevant information and materials.</li>
              <li>
                Illustrating concepts by designing examples of art arrangement, size, type size and style and submitting them for approval. </li>

              <li>
                Preparing finished art by operating necessary equipment and software. </li>
              <li>
                Contributing to team efforts by accomplishing tasks as needed. </li>
              <li>
                Communicating with clients about layout and design.  </li>
              <li>
                Reviewing final layouts and suggesting improvements when necessary.</li>
              <li>Creating a wide range of graphics and layouts for product illustrations, company logos, and websites with software such as photoshop.</li>
            </ul>
            <h3>Required Skills & Qualifications:</h3>
            <ul>
              <li>Bachelor's degree.
              </li>
              <li>
                Experience as a graphic designer or in a related field.    </li>
              <li>
                Demonstrable graphic design skills with a strong portfolio.    </li>
              <li>
                Proficiency with required desktop publishing tools, including Photoshop, InDesign Quark, and Illustrator.   </li>
              <li>
                A strong eye for visual composition.   </li>
              <li>Effective time management skills and the ability to meet deadlines.</li>
              <li>Understanding of marketing, production, website design, corporate identity, product packaging, advertisements, and multimedia design.</li>

            </ul></div>)}
          {modalData.contentKey === 'ss' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Conduct in-depth keyword research and optimize website content, including meta tags, headers, and images.</li>
              <li>
                Monitor and analyze website performance using tools like Google Analytics, SEMrush etc.</li>

              <li>
                Develop and implement effective on-page and off-page SEO strategies to improve search rankings.</li>
              <li>
                Stay up-to-date with industry trends, search engine algorithms, and ranking factors to ensure the website remains competitive.</li>
              <li>
                Generate detailed SEO reports and provide actionable insights to improve site performance and user engagement.</li>
              <li>Perform regular SEO audits to identify areas for improvement and implement changes as necessary.</li>
            </ul>
            <h3>Required Skills & Qualifications:</h3>
            <ul>
              <li>Bachelor's degree in Marketing, Business, or a related field.
              </li>
              <li>
                Proven experience in SEO and a strong understanding of search engine algorithms and ranking methods.    </li>
              <li>
                Proficiency in SEO tools such as Google Analytics, SEMrush, or similar platforms.    </li>
              <li>
                Strong analytical skills with the ability to identify trends, issues, and opportunities.   </li>
              <li>
                Excellent written and verbal communication skills for creating optimized content and collaborating with teams.</li>
              <li>Attention to detail and a results-driven mindset with a focus on continuous improvement.</li>

            </ul></div>)}
          {modalData.contentKey === 'rd' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Develop and maintain scalable, high-performance web applications.</li>
              <li>
                Collaborate with cross-functional teams to define and implement innovative solutions.</li>

              <li>
                Utilize React JS for front-end development and Node JS for back-end development.</li>
              <li>
                Design and integrate seamless user interfaces using HTML, CSS, Bootstrap, and JavaScript. </li>
              <li>
                Work with REST APIs to integrate back-end services with front-end applications.</li>
              <li>
                Ensure the responsiveness and optimization of web applications across various platforms and devices.</li>
              <li>Participate in code reviews and maintain high-quality code standards.</li>
              <li>Troubleshoot and resolve application issues, ensuring smooth user experiences.</li>
              <li>Contribute to the design and implementation of user interfaces.</li>
              <li>Stay up-to-date with the latest trends and technologies in software development.</li>
            </ul>
            <h3>Required Skills & Qualifications:</h3>
            <ul>
              <li>Strong experience in both front-end and back-end web development.
              </li>
              <li>
                Proficiency in JavaScript, React JS, HTML, CSS, and Bootstrap. </li>
              <li>
                Hands-on experience with React JS tools and development libraries.   </li>
              <li>
                Familiarity with integrating REST APIs into front-end applications. </li>
              <li>
                Strong problem-solving skills and ability to optimize application performance.</li>
              <li>Knowledge of scalable web application design principles.</li>
              <li>Experience in user interface (UI) design and usability principles.</li>
              <li>Hands-on experience with Node JS for server-side development.</li>
              <li>Familiarity with MSSQL or other database management systems is a plus.</li>

            </ul></div>)}
          {modalData.contentKey === 'webd' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Develop and design responsive web applications.</li>
              <li>
                Design and implement UI components using Bootstrap, React JS, HTML, and CSS.</li>

              <li>
                Optimize web applications for maximum speed and scalability.</li>
              <li>
                Collaborate with backend developers and designers to integrate APIs and enhance user experience. </li>
              <li>
                Debug and fix UI-related issues.</li>
              <li>
                Keep up with the latest trends in React.js and frontend development.
              </li>

            </ul>
            <h3>Required Skills & Qualifications:</h3>
            <ul>
              <li>Proficiency in React.js and its core principles.
              </li>
              <li>
                Strong knowledge of Bootstrap, HTML, and CSS for responsive design. </li>
              <li>
                Experience in working with RESTful APIs and integrating backend services.   </li>
              <li>
                Familiarity with Git version control. </li>
              <li>
                Ability to write clean, maintainable, and efficient code.</li>
              <li>Strong problem-solving skills and attention to detail.</li>
              <li>Experience with UI/UX design principles.</li>
              <li>Basic understanding of Node.js or backend development.</li>

            </ul></div>)}
          {modalData.contentKey === 'sd' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Develop and customize Shopify themes, ensuring a responsive, user-friendly design with an intuitive shopping experience.</li>
              <li>
                Implement custom features and functionalities, including new modules, apps, and third-party integrations to enhance the Shopify platform.</li>

              <li>
                Integrate and configure various payment gateways (PayPal, Stripe, etc.) to ensure smooth transaction processing and secure checkout experiences.</li>
              <li>
                Implement SMS notifications and integrations to keep customers informed and improve engagement. </li>
              <li>
                Write clean, well-documented, and optimized code to improve website performance and speed.
              </li>
              <li>
                Conduct thorough testing, identify issues, and provide quick resolutions to ensure the site operates flawlessly.
              </li>
              <li>Work closely with designers, product managers, and other developers to align on project requirements and deadlines.</li>
              <li>Provide ongoing support, troubleshoot issues, and perform updates and enhancements as needed.</li>
              <li>Utilize HTML, CSS, and JavaScript to build optimized and scalable front-end and back-end features, ensuring cross-browser compatibility and mobile responsiveness.</li>

            </ul>
            <h3>Required Skills & Qualifications:</h3>
            <ul>
              <li>Experience: 2+ years of experience with Shopify development, including theme customization and app integration.
              </li>
              <li>
                Technical Skills: Proficiency in HTML5, CSS3, and JavaScript. </li>
              <li>
                Experience with Liquid, Shopify's templating language. </li>
              <li>
                Strong knowledge of Shopify's APIs for integrations.</li>
              <li>
                Familiarity with payment gateway integration (PayPal, Stripe, etc.).</li>
              <li>Experience with UI/UX design principles.</li>
              <li>Knowledge of version control systems (Git).</li>
              <li>Ability to write optimized code for fast loading times and performance.</li>
              <li>Problem-Solving: Excellent debugging and problem-solving skills.</li>
              <li>Attention to Detail: Strong focus on delivering pixel-perfect designs and high-quality code.</li>
              <li>Communication Skills: Ability to communicate technical concepts effectively with non-technical stakeholders.</li>
              <li>Portfolio: A portfolio or examples of previous Shopify development projects is highly preferred.</li>

            </ul>
            <h3>Desirable skills:</h3>
            <ul>
              <li>Experience with Shopify Plus is a plus.</li>
              <li>Familiarity with Shopify apps (Klaviyo, ReCharge, etc.) and integration best practices.</li>
              <li>Knowledge of SEO best practices for Shopify sites.
              </li>
            </ul>


          </div>)}
          {modalData.contentKey === 'smmi' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Assist in planning and executing social media campaigns.
              </li>
              <li>Create and schedule posts for platforms like Instagram, Facebook, LinkedIn, etc.</li>
              <li>Brainstorm and contribute ideas for content creation and trends.</li>
              <li>Assist in shooting and making engaging reels for social media.</li>
              <li>Engage with the audience by responding to comments and messages.</li>
              <li>Monitor social media trends, new features, and competitor activities.</li>
              <li>Track performance metrics and assist in preparing reports.</li>
            </ul>
            <h3>Qualifications:</h3>
            <ul>
              <li>Currently pursuing or recently completed a degree in Marketing, Communications, or a related field.</li>
              <li>Creative mindset with confidence in contributing new ideas.</li>
              <li>Familiarity with social media platforms and their features.</li>
              <li>Basic knowledge of content creation tools (e.g., Canva) is a plus.
              </li>
              <li>Comfortable in assisting with video shoots and reels creation.</li>
              <li>Good communication and teamwork skills.</li>

            </ul></div>)}
          {modalData.contentKey === 'gdi' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Assist in designing visual content for social media, websites, and marketing materials.              </li>
              <li>Work with the graphics team to develop creative concepts and design layouts.</li>
              <li>Create and edit banners, posters, brochures, and other branding materials.</li>
              <li>Ensure brand consistency across all design projects.</li>
              <li>Support in designing presentations, infographics, and digital ads.</li>
              <li>Stay updated with design trends, tools, and industry best practices.</li>
              <li>Organize and manage design assets and files for team use.</li>
            </ul>
            <h3>Qualifications:</h3>
            <ul>
              <li>Currently pursuing or recently completed a degree in Marketing, Communications, or a related field.</li>
              <li>Creative mindset with attention to detail.</li>
              <li>Familiarity with design software (e.g., Photoshop, Illustrator, Canva, or Figma).</li>
              <li>Basic understanding of layout, typography, and color theory.

              </li>
              <li>Ability to take feedback and make design improvements.</li>
              <li>Good communication and teamwork skills.</li>

            </ul></div>)}
          {modalData.contentKey === 'vdi' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Assist in editing and producing high-quality videos for social media, marketing campaigns, and branding purposes.           </li>
              <li>Trim, cut, and arrange video clips to create engaging content.</li>
              <li>Add effects, transitions, text overlays, and music to enhance videos.</li>
              <li>Assist in shooting and brainstorming ideas for reels, shorts, and promotional videos.</li>
              <li>Optimize video content for different social media platforms like Instagram, YouTube, and LinkedIn.</li>
              <li>Stay updated with the latest video trends and editing techniques.</li>
              <li>Organize and manage video files for easy access and editing.</li>
            </ul>
            <h3>Qualifications:</h3>
            <ul>
              <li>Currently pursuing or recently completed a degree in Film, Media, Communications, or a related field.</li>
              <li>Basic knowledge of video editing tools like Adobe Premiere Pro, Final Cut Pro etc.</li>
              <li>Creative mindset with attention to detail and storytelling.</li>
              <li>Familiarity with social media video trends (reels, shorts, etc.)

              </li>
              <li>Comfortable assisting with video shoots when required.</li>
              <li>Good teamwork and time management skills.</li>

            </ul></div>)}
          {modalData.contentKey === 'sqld' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Develop, test, and optimize SQL queries, stored procedures, functions, and triggers.  </li>
              <li>Design and maintain relational databases to meet business needs.</li>
              <li>Optimize database performance by indexing, partitioning, and query tuning.</li>
              <li>Ensure data integrity, security, and backup procedures.</li>
              <li>Work with developers to integrate databases with applications.</li>
              <li>Develop and maintain reports using SQL-based reporting tools.</li>
              <li>Troubleshoot database-related issues and provide solutions.</li>
              <li>Stay updated with new database technologies and best practices.</li>
            </ul>
            <h3>Qualifications:</h3>
            <ul>
              <li>Proven experience as an SQL Developer or in a similar role.</li>
              <li>Strong knowledge of SQL, MySQL, or MS SQL Server.</li>
              <li>Experience in database design, normalization, and indexing.</li>
              <li>Understanding of cloud-based databases (AWS RDS, Azure SQL, Google Cloud SQL) is a plus.
              </li>
              <li>Ability to write, debug, and optimize complex SQL queries.</li>
              <li>Bachelor's degree in Computer Science, IT, or a related field.</li>
              <li>Experience with big data, data warehousing, and NoSQL databases (MongoDB, Cassandra) is a plus.</li>
              <li>Knowledge of other data visualization tools is an advantage.
              </li>

            </ul></div>)}
          {modalData.contentKey === 'rdi' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Assist in developing and maintaining React-based web applications. </li>
              <li>Work on UI components, state management, and API integrations.</li>
              <li>Optimize applications for performance and responsiveness.</li>
              <li>Debug and troubleshoot frontend issues.</li>
              <li>Collaborate with designers and backend developers for seamless integration.</li>
              <li>Stay updated with the latest React.js trends and best practices.</li>

            </ul>
            <h3>Qualifications:</h3>
            <ul>
              <li>Currently pursuing or recently completed a degree in Computer Science, IT, or a related field.</li>
              <li>Basic understanding of React.js and JavaScript.</li>
              <li>Familiarity with HTML, CSS, and component-based architecture.</li>
              <li>Basic knowledge of Git and version control is a plus.
              </li>
              <li>Eager to learn and improve coding skills.</li>
              <li>Good communication and teamwork skills.</li>

            </ul></div>)}
          {modalData.contentKey === 've' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Edit and enhance videos for social media, marketing, and promotional content. </li>
              <li>Trim footage, add effects, transitions, and subtitles to create engaging videos.</li>
              <li>Collaborate with the creative team to ensure brand consistency.</li>
              <li>Optimize videos for different platforms like YouTube, Instagram, and Facebook.</li>
              <li>Stay updated with video editing trends, tools, and techniques.</li>
              <li>Manage and maintain a library of video assets and projects.</li>

            </ul>
            <h3>Qualifications:</h3>
            <ul>
              <li>Currently pursuing or recently completed a degree in Media, Film, Communications, or a related field.</li>
              <li>Creative mindset with a keen eye for detail.</li>
              <li>Familiarity with video editing software e.g., Adobe Premiere Pro, Final Cut Pro, AfterEffect, etc.</li>
              <li>Basic understanding of color correction, audio editing, and motion graphics is a plus.
              </li>
              <li>Comfortable working with different video formats and resolutions.</li>
              <li>Good communication and teamwork skills.</li>

            </ul></div>)}
          {modalData.contentKey === 'p' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Conduct product photoshoots for marketing and branding purposes.</li>
              <li>Travel to client sites to capture photos and videos as required.</li>
              <li>Plan and set up lighting, backgrounds, and equipment for high-quality shoots.</li>
              <li>Edit and enhance images to ensure professional visual appeal.</li>
              <li>Collaborate with the creative team to develop engaging content.</li>
              <li>Organize and maintain a library of photos and raw files.</li>
              <li>Stay updated with photography trends, tools, and editing techniques.</li>

            </ul>
            <h3>Qualifications:</h3>
            <ul>
              <li>Must have prior working experience in photography.</li>
              <li>Degree or certification in Photography, Media, or a related field is a plus.</li>
              <li>Creative mindset with strong attention to detail.</li>
              <li>Proficiency in DSLR/Mirrorless cameras, lighting setups, and composition techniques.
              </li>
              <li>Knowledge of photo and video editing software (e.g., Photoshop, Lightroom, Premiere Pro).</li>
              <li>Comfortable with traveling for on-site shoots.</li>
              <li>Good communication and teamwork skills.</li>

            </ul></div>)}
          {modalData.contentKey === 'pi' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Assist in product photoshoots for marketing and branding purposes.</li>
              <li>Support in setting up lighting, backgrounds, and camera equipment for shoots.</li>
              <li>Capture and edit photos and short videos as per brand requirements.</li>
              <li>Work closely with the creative team to develop engaging visuals.</li>
              <li>Organize and manage a library of photos and raw files.</li>
              <li>Research and stay updated on photography trends and techniques.</li>

            </ul>
            <h3>Qualifications:</h3>
            <ul>
              <li>Currently pursuing or recently completed a degree in Photography, Media, or a related field.</li>
              <li>Basic knowledge of DSLR/Mirrorless cameras and lighting setups.</li>
              <li>Familiarity with photo editing tools (e.g., Photoshop, Lightroom, Canva).</li>
              <li>Creative mindset with attention to detail.
              </li>
              <li>Willingness to learn and take feedback.</li>
              <li>Good communication and teamwork skills.</li>

            </ul></div>)}
          {modalData.contentKey === 'fd' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Designing, developing, testing, maintaining, and deploying software in the Flutter framework and Dart language.</li>
              <li>Developing user interface components and implementing them by following well-known Flutter / Dart workflows and practices.</li>
              <li>Communicating with product and engineering leads to implementing business and project objectives.</li>
              <li>Work closely with the creative team to develop engaging visuals.</li>
              <li>Code review of team members' commits as part of CI/CD cycle.</li>
              <li>Participate in testing, quality assurance and bug fixes as part of the CI/CD cycle.</li>

            </ul>
            <h3>Qualifications:</h3>
            <ul>
              <li>2 to 5 years of experience developing mobile apps natively in iOS and/or Android.</li>
              <li>2 years of commercial experience developing and delivering Flutter based apps.</li>
              <li>Good understanding of state management, Flutter flavors and app architecture.</li>
              <li>Strong communications and consultative skills. Self-initiated and proactive.
              </li>
              <li>Experience in a remote software development environment.</li>
              <li>Experience with building apps and software for consumer goods is a bonus.</li>
              <li>A degree in software engineering, computer science, or software development is a plus.</li>

            </ul></div>)}
          {modalData.contentKey === 'wd' && (<div>
            <h3>Key Responsibilities:</h3>
            <ul>
              <li>
                Develop, customize, and maintain WordPress websites as per project requirements.</li>
              <li>Design and implement responsive web pages using WordPress themes and page builders.</li>
              <li>Troubleshoot and resolve bugs, performance issues, and website errors.</li>
              <li>Collaborate with the design and content team to ensure website consistency.</li>
              <li>Stay updated with the latest WordPress trends, plugins, and updates.</li>

            </ul>
            <h3>Qualifications:</h3>
            <ul>
              <li>Currently pursuing or recently completed a degree in Computer Science, IT, or a related field.</li>
              <li>Familiarity with WordPress, Elementor, or other page builders.</li>
              <li>Basic understanding of HTML, CSS, and JavaScript.</li>
              <li>Knowledge of SEO best practices and website optimization is a plus.
              </li>
              <li>Creative mindset with problem-solving skills.</li>
              <li>Good communication and teamwork skills.</li>
              <li>A degree in software engineering, computer science, or software development is a plus.</li>

            </ul></div>)}


        </Modal.Body>
        <Modal.Footer><span class="btn btn-primary talk mt-5px" onClick={() => {
          handleShowJobTitle(modalData.title)
          handleCloseModal()
        }}> Apply Now </span></Modal.Footer>
      </Modal>
    </>
  );
}

export default Careers;
